<template>
  <div class="message-view">
    <div class="jelly">
      <component :is="`mood-${mood}`" />
    </div>
    <slot />
  </div>
</template>
<script>
import MoodSearch from '@/assets/moods/mood-search.svg'
import MoodSorry from '@/assets/moods/mood-sorry.svg'
export default {
  name: 'Home',
  components: {
    MoodSorry,
    MoodSearch
  },
  props: {
    mood: {
      type: String,
      default: 'search'
    }
  }
}
</script>
<style scoped>
.message-view {
  align-self: center;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 20px;
}
.jelly {
  background: var(--app-color-layout);
  border-radius: 20px;
  padding: 20px;
  padding-bottom: 14px;
}
.jelly > svg {
  width: 120px;
  /* opacity: 0.5; */
}
</style>
