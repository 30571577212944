<template>
  <v-sheet class="search-view">
    <message-view class="no-search" v-if="!searchValue">
      To search something, please use the bar above.
    </message-view>
    <app-spinner v-else-if="!hasResult" />
    <message-view class="no-result" mood="sorry" v-else-if="isEmpty">
      Oups, there are no results for '{{ searchValue }}'
    </message-view>
    <div v-else class="result">
      <h2>Result for {{ searchValue }}</h2>
      <v-list dense>
        <template v-for="(item, index) in searchResult">
          <v-subheader v-if="item.header" :key="`header-${index}`">{{
            item.header
          }}</v-subheader>
          <drive-item
            :item="item"
            :key="`content-${index}`"
            @click="clearSearch"
          />
        </template>
      </v-list>
    </div>
  </v-sheet>
</template>

<script>
import searchMixin from '../../mixins/searchMixin'
import DriveItem from '../misc/DriveItem.vue'
import AppSpinner from '../misc/AppSpinner.vue'
import MessageView from '../misc/MessageView.vue'
export default {
  name: 'Search',
  mixins: [searchMixin],
  components: { AppSpinner, DriveItem, MessageView },
  props: { searchValue: String },
  data: () => ({
    isEmpty: true,
    type: 'deep'
  }),
  watch: {
    async searchValue(value) {
      console.log(this.search, value)
      await this.search(value)
    }
  },
  created() {
    console.log(this.searchValue)
    if (this.searchValue) {
      this.search(this.searchValue)
    }
  }
}
</script>

<style scoped>
.search-view {
  flex: 1;
}
.no-search,
.no-result {
  max-width: 250px;
}
</style>
